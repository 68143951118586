"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.SiteFooter = SiteFooter;

var _react = _interopRequireDefault(require("react"));

var _Link = require("../../react-static/components/Link"); // import { LinkContext } from "../../context/LinkContext";


function SiteFooter(_ref) {
  var siteStore = _ref.siteStore;
  return _react.default.createElement("p", null, "\xA9", " ", siteStore && siteStore.siteDisplayName && _react.default.createElement(_Link.Link, {
    to: siteStore.siteHomeUrl
  }, siteStore.siteDisplayName), " ", "- ", _react.default.createElement(_Link.Link, {
    to: "/tos/"
  }, "Terms of Service"), " -", " ", _react.default.createElement(_Link.Link, {
    to: "/disclaimer/"
  }, "Disclaimer"));
}