"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

exports.__esModule = true;
exports.css = exports.styled = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components")); // STYLED COMPONENTS


var styled = _styledComponents.default;
exports.styled = styled;
var themedCss = _styledComponents.css;
exports.css = themedCss;