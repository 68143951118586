"use strict";

exports.__esModule = true;
exports.trimSlashes = trimSlashes;
exports.urlify = urlify;

function trimSlashes(str) {
  return str.replace(/^\/{1,}/g, "").replace(/\/{1,}$/g, "");
} // 1: doesn't strip single /
// 2: strips multiple trailing slashes


function trimTrailingSlashUrl(url) {
  if (!url) {
    return url;
  }

  return url.replace(/^(.+?)\/*?$/, "$1");
}

function urlify(url) {
  // return trimTrailingSlashUrl(url);
  // include trailing slash taken from to cater for query string and #:
  // https://stackoverflow.com/questions/11531363/javascript-jquery-add-trailing-slash-to-url-if-not-present/11531417
  return url.replace(/\/?(\?|#|$)/, "/$1");
}