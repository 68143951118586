"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.GTags = void 0;

var _react = _interopRequireDefault(require("react"));

var _client = require("../html/client");

var GTags = function GTags(_ref) {
  var trackingId = _ref.trackingId,
      Head = _ref.Head;

  if ((0, _client.isClient)()) {
    var gtag = function gtag() {
      dataLayer.push(arguments);
    };

    window.GA_TRACKING_ID = trackingId;
    window.dataLayer = window.dataLayer || [];
    gtag("js", new Date());
    gtag("config", trackingId);
    window.gtag = gtag;
  }

  return _react.default.createElement(Head, null, _react.default.createElement("script", {
    async: true,
    src: "https://www.googletagmanager.com/gtag/js?id=GA_TRACKING_ID"
  }));
};

exports.GTags = GTags;