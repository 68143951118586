"use strict";

exports.__esModule = true;
exports.screenLg = exports.screenMd = exports.screenSm = exports.screenXs = exports.screenXxl = exports.screenXl = void 0;
var screenXl = "1200px";
exports.screenXl = screenXl;
var screenXxl = "1600px";
exports.screenXxl = screenXxl;
var screenXs = "480px";
exports.screenXs = screenXs;
var screenSm = "576px";
exports.screenSm = screenSm;
var screenMd = "768px";
exports.screenMd = screenMd;
var screenLg = "992px";
exports.screenLg = screenLg;